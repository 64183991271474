import Logo from "./valobit.png";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Metamask from "./assets/images/Metamask.png";
import Connectwallet from "./assets/images/trustwallet.png";
import {
  AiOutlineUserAdd,
  AiFillTwitterCircle,
  AiOutlineAlignRight,
} from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import backendConfig from "./config/config";
import Web3 from "web3";
import { useEffect } from "react";
import sunflowerABI from "./ABI/vbitmlm.json";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { arbitrum, mainnet, polygon, bsc, sepolia } from "wagmi/chains";
import ETH from "./switchnetwork";
import $ from "jquery";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import Bannerimage1 from "./assets/images/Mask-new-banner-bg-inner-1.png";
import Bannerimage2 from "./assets/images/Mask-new-banner-bg-inner-2.png";
import Bannerimage3 from "./assets/images/Mask-new-banner-bg-inner-3.png";
import Bannerimage4 from "./assets/images/Mask-new-banner-bg-inner-4.png";
import { FaTwitter } from "react-icons/fa";
import { SiDiscord } from "react-icons/si";
import config from "./config/config";
import VBIT from "./switchnetwork";
import { FaInstagram } from "react-icons/fa6";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";

function App() {

  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState("");
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);

  const { open, close } = useWeb3Modal();
  const [userexiststatus, setuserexiststatus] = useState(false);


  const handleClose = () => {
    setShow(false);
  };

  const joinbuttonshow = async() => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    let userMeta = JSON.parse(userMetaAddress);
    let addr = userMeta.state.data.account;
    if(addr == undefined) {
      let metaaddress = localStorage.getItem("address");
      if(metaaddress == null) {
        handleShow()
        joinnowbutton()
      }
      else {
        joinnowbutton()
      }
    }
    else {
      joinnowbutton()
    }
  }

  const walletopen = async () => {
    let data = await open();
    setInterval(() => {
      if (localStorage.getItem("wagmi.connected")) {
        close();
        window.location.reload(true);
      }
    }, 2000);
  };

  function isMobileDevice() {
    let check = false;
    (function (a) {
      if (
        "ontouchstart" in window ||
        "onmsgesturechange" in window ||
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);

    return check;
  }

  const walletswicthethereum = async () => {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: config.chainId,
          chainName: "VALOBIT mainnet",
          rpcUrls: [config.rpcUrls],
          blockExplorerUrls: [config.BKC_ContarctLink],
          nativeCurrency: {
            name: "VALOBIT mainnet",
            symbol: "VBIT",
            decimals: 18,
          },
        },
      ],
    });
  }


  const metamaskconnect = async () => {
    if (window.ethereum) {
      let backchain = backendConfig.chainId;
      let metchainid = window.ethereum.chainId;
      if (backchain == metchainid) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        walletswicthethereum();

        let address = localStorage.getItem("address");
        walletdetails();
        setUserAddress(
          address.substring(0, 5) +
          "..." +
          address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        walletdetails();
        walletswicthethereum();
        setUserAddress(
          address.substring(0, 5) +
          "..." +
          address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      }
    } else {
      toast.warn("Please install metamask");
      openInNewTab(
        `https://metamask.io/download/`
      );
    }
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };

  const connectAddress = async () => {
    const userDataString = localStorage.getItem("wagmi.store");
    const userData = JSON.parse(userDataString);
    let address = userData.state.data.account;
    console.log(address, "address");
    if (address) {
      window.location.reload();
      trustWallet(address);
      setUserAddress(
        address.substring(0, 5) + "..." + address.substring(address.length - 4)
      );
      $("#joinnowbuttonhide").hide();
    }
  };

  const trustWallet = async (address) => {
    let web3js = new Web3(backendConfig.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      backendConfig.BKC_MLMContract
    );
    let userexists = await sunflowerContract.methods
      .isUserExists(address)
      .call();
    if (userexists == true) {
      setuserexiststatus(true);
    } else {
      setuserexiststatus(false);
    }
  };

  const chains = [VBIT];
  const projectId = "1f0b9efb280db82bff5edc4689ffa76a";

  const trustWalletConnect = async () => {
    const provider = await EthereumProvider.init({
      projectId: "1f0b9efb280db82bff5edc4689ffa76a",
      chains: [1, 56, 2535]
    })
    provider.on('disconnect', (code, reason) => {
      window.location.reload();
      localStorage.clear()
    });
  }

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  const disconnect = () => {
    localStorage.clear();
    window.location.reload();
  };

  const walletdetails = async () => {
    const ethereumAddress = wagmiConfig.ethereumAddress;
    let result = localStorage.getItem("address");
    if (result) {
      let address = result;
      let web3js = new Web3(backendConfig.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        backendConfig.BKC_MLMContract
      );
      let userexists = await sunflowerContract.methods
        .isUserExists(address)
        .call();
      if (userexists == true) {
        setuserexiststatus(true);
      } else {
        setuserexiststatus(false);
      }
    }
  };

  useEffect(() => {
    let userMetaAddress = localStorage.getItem("wagmi.store");
    let userMeta = JSON.parse(userMetaAddress);
    let addr = userMeta.state.data.account;
    let metaaddress = localStorage.getItem("address");
    if (addr != null) {
      setUserAddress(
        addr.substring(0, 5) + "..." + addr.substring(addr.length - 4)
      );
      $("#joinnowbuttonhide").show();
      userdashboardshow(addr);
    } else if (metaaddress != null) {
      setUserAddress(
        metaaddress.substring(0, 5) +
        "..." +
        metaaddress.substring(metaaddress.length - 4)
      );
      $("#joinnowbuttonhide").show();
      userdashboardshow(metaaddress);
    } else {
      $("#joinnowbuttonhide").hide();
    }
    trustWalletConnect()
  }, []);

  const userdashboardshow = async (userMetaAddress) => {
    let web3js = new Web3(backendConfig.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      backendConfig.BKC_MLMContract
    );
    let userexists = await sunflowerContract.methods
      .isUserExists(userMetaAddress)
      .call();
    if (userexists == true) {
      setuserexiststatus(true);
      $("#buypackagehide").show();
    } else {
      setuserexiststatus(false);
    }
  };




  const joinnowbutton = async () => {
    if (localStorage.getItem('address')) {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let web3js = new Web3(backendConfig.rpcUrls);
        let sunflowerContract = new web3js.eth.Contract(
          sunflowerABI,
          backendConfig.BKC_MLMContract
        );
        let userexists = await sunflowerContract.methods
          .isUserExists(accounts[0])
          .call();
        if (userexists) {
          navigate("/dashboard");
        } else {
          navigate("/register");
        }
      }
    }
    else {
      const userMetaAddress = localStorage.getItem("wagmi.store");
      const userMeta = JSON.parse(userMetaAddress);
      const addr = userMeta.state.data.account;

      if (addr != null) {
        let web3js = new Web3(backendConfig.rpcUrls);
        let sunflowerContract = new web3js.eth.Contract(
          sunflowerABI,
          backendConfig.BKC_MLMContract
        );
        console.log(addr);
        let userexists = await sunflowerContract.methods
          .isUserExists(addr)
          .call();
        console.log(userexists, 'join users');
        if (userexists) {
          console.log('true');
          navigate("/dashboard");
        } else {
          console.log('false');
          navigate("/register");
        }
      }
    }
  }

  useEffect(() => {

    $(document).ready(function () {
      $(window).scroll(function () {
        if ($(document).scrollTop() > 50) {
          $('#top-navbar-new').addClass('total-new-banner-bg');
        } else {
          $('#top-navbar-new').removeClass('total-new-banner-bg');
        }
      });
    });

  }, []);


  return (
    <div className="App total-new-banner-bg">
      <ToastContainer />
      <nav class="navbar navbar-expand-lg fixed-top" id="top-navbar-new">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white">
              <AiOutlineAlignRight />
            </span>
          </button>

          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 gap-3">
            </ul>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">

              <li class="nav-item" id="joinnowbuttonhide">
                {userexiststatus == true ? (
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={joinnowbutton}
                  >
                    dashboard
                  </a>
                ) : (
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={joinnowbutton}
                  >
                    Join Now
                  </a>
                )}
              </li>
              {userAddress ? (
                <a class="nav-link main-nav-connect-button">{userAddress}</a>
              ) : (
                <a class="nav-link main-nav-connect-button" onClick={handleShow}>
                  Connect Wallet
                </a>
              )}
              <li className="nav-item">
                {userAddress ? (
                  <button class="nav-link main-nav-connect-button" onClick={disconnect}>
                    Disconnect
                  </button>
                ) : (
                  <a></a>
                )}
              </li>

              <div className="modal-section">
                <WagmiConfig config={wagmiConfig}>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    className="d-flex justify-content-center"
                    centered
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="pt-1">
                      <div className="d-flex flex-lg-row flex-column gap-lg-1 gap-3">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleClose();
                          metamaskconnect();
                        }}
                        className="connect-metamask-button"
                      >
                        <img src={Metamask} style={{ width: "30px" }} alt="" />
                        Connect Metamask
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => {
                          walletopen();
                          handleClose();
                          connectAddress();
                        }}
                        className="connect-metamask-button ms-lg-3"
                      >
                        <img
                          src={Connectwallet}
                          style={{ width: "30px" }}
                          className="me-2"
                          alt=""
                        />
                        Trust Wallet
                      </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </WagmiConfig>
                <Web3Modal
                  enableAccountView={true}
                  projectId={projectId}
                  ethereumClient={ethereumClient}
                />
              </div>
            </ul>
          </div>
        </div>
      </nav>
      <section className="banner-new-top-new-1">
        <div className="container-fluid">
          <div className="row min-vh-100 align-items-center justify-content-center col-lg-10 mx-auto flex-lg-row-reverse" >
            <div className="col-lg-6">
              <img className="banner-new-top-images-1" src={Bannerimage1} alt="Bannerimage2" />
            </div>
            <div className="col-lg-6">
              <h1 className="mask-new-banner-1 pt-lg-4">Decentralized and transparent, VBITCAKE runs on the Valobit blockchain, Empowering direct, secure transactions with automated payouts, bypassing intermediaries. </h1>
              <p className="mb-5 text-align-contents-hm pt-lg-2">VBITCAKE generates income through a Network-Based System When a participant successfully recruits someone into the program by filling a matrix slot, they earn cryptocurrency rewards. However, if their own recruitment efforts don't fill the matrix quickly enough, any surplus referrals automatically spill over and benefit others further down the network, still offering earning potential. This spillover mechanism ensures continuous activity and income distribution within the system.</p>

              {userexiststatus == true ? (
                <button
                  class="mask-new-button-1"
                  onClick={joinnowbutton}
                >
                  dashboard
                </button>
              ) : (
                <button
                  class="mask-new-button-1"
                  onClick={joinbuttonshow}
                >
                  Join Now
                </button>
              )}
            </div>

            <div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-new-top-new-2">
        <div className="container-fluid">
          <div className="row align-items-center justify-content-center col-lg-10 mx-auto" >
            <div className="col-lg-6">
              <img className="banner-new-top-images-1 mt-0" src={Bannerimage2} alt="Bannerimage2" />
            </div>
            <div className="col-lg-6">
              <h1 className="mask-new-banner-2">VALOBIT WORKS MINE</h1>
              <p className="text-align-contents-hm">VBITCAKE, once touted as a revolutionary crypto moneymaker, is now infamous as a decentralized multi-level marketing sunflower scheme. While it operates on the blockchain, claiming freedom from central control, the core principles remain strikingly similar to traditional, centralised MLMs.
              </p><p className="pt-0 mb-5 text-align-contents-hm">Imagine a sunflower of slots you purchase with cryptocurrency. Each purchase earns you rewards when someone you directly recruit fills a slot under you. But the real "magic" lies in spillovers. When your pyramid overflows, excess recruits get placed below others, earning you indirect rewards. This constant recruitment pressure fuels the system, promising passive income for early joiners.</p>
              
            </div>
            <div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-new-top-new-3 py-5">
        <div className="container-fluid">
          <h1 className="mask-new-banner-1 text-center mb-4">Income Plan</h1>
          <p className="mask-new-banner-3 mb-5 ">
 <p className="text-center"> Imagine a marketing strategy that blossoms like a sunflower, radiating opportunity at every level. We offer just that, designed to empower your success through </p>
<p> <span style={{ color: "#0197F3" }}>Structured Growth: </span>Our system resembles a sunflower, with clear levels of achievement leading to rewards. As you progress, you unlock new opportunities and increased earning potential.</p>
<p> <span style={{ color: "#0197F3" }}>Direct Commissions: </span> Earn immediate commissions for your efforts, directly proportional to your contributions. The faster you grow, the more you reap the rewards.</p>
<p> <span style={{ color: "#0197F3" }}> Integrated Rewards: </span>  Our unique system utilizes blockchain technology, offering our own "great" coins as part of the reward structure. These coins hold value and provide you with additional avenues for growth and engagement.</p>

 <p className="text-center">This innovative approach fosters a dynamic and rewarding ecosystem, propelling your success and fueling your journey to the top. Join us and experience the power of growth, nurtured by the sunflower structure. </p></p>
          <div className="row align-items-start justify-content-center col-lg-10 mx-auto banner-new-top-new-3-inner-1" >
            <div className="col-lg-6 banner-new-top-new-3-inner-1-col-1 py-5">
              <h5 className="mask-new-banner-4 " style={{ color: "#8787FF" }}>Direct Referral income 25%</h5>
              <p className="w-75">Direct referral income is money earned when someone signs up or makes a purchase using your unique referral link or code. It's like getting a commission for recommending a product or service you like.</p>
              <img className="banner-new-top-images-2" src={Bannerimage3} alt="Referral Income" />
              <h5 className="income-tx-sub">Leader can get 25% Each Direct Referral packages</h5>
            </div>
            <div className="col-lg-6 py-5 ps-lg-5">
              <h5 className="mask-new-banner-4" style={{ color: "#0197F3" }}>Level Income 1%</h5>
              <p className="w-75 ">In MLM marketing, "Level Income" refers to commissions earned from the sales of your downline team members, across various levels in the network hierarchy
</p>
              <img className="banner-new-top-images-2" src={Bannerimage4} alt="Level Income" />
              <h5 className="income-tx-sub pt-lg-5 ">Can Earn 1% of Each Level unto 75 LEVELS</h5>
            </div>
            <div>
            </div>
          </div>
        </div>
      </section>

      <section className="banner-new-top-new-4 py-lg-5 py-3">
        <div className="container-fluid py-lg-5">
          <div className="row align-items-center justify-content-center col-lg-10 mx-auto g-4" >
            <div className="col-lg-6">
              <h4 className="mb-4">Social Media</h4>
              <div className="d-flex flex-row gap-3">
                <a href="https://twitter.com/buyvalobitcoin" className="mask-new-icon-1" target="_blank"><FaTwitter /></a>
                <a href="https://www.instagram.com/buyvalobitcoin/" className="mask-new-icon-1" target="_blank"><AiFillInstagram /></a>
                <a href="https://www.facebook.com/buyvalobitcoin/" className="mask-new-icon-1" target="_blank"><FaFacebookF /></a>
                <a href="https://telegram.me/+VqpPwg8p3exkeZFe" className="mask-new-icon-1" target="_blank"><FaTelegramPlane /></a>
                {/* <a href="" className="mask-new-icon-1"><SiDiscord /></a> */}
              </div>
              <div className="mt-4">
              <a className="explorer-text" href="https://valobitscan.io/" > vbit explorer link</a>
              </div>
            </div>
            <div className="col-lg-2">
              <h4 className="mb-4">About</h4>
              <li className="list-group-item mb-1"><a href="" style={{ opacity: "0.8" }}>Contact</a></li>
              <li className="list-group-item mb-1"><a href="" style={{ opacity: "0.8" }}>Community</a></li>
              <li className="list-group-item mb-1"><a href="" style={{ opacity: "0.8" }}>Whitepaper</a></li>
            </div>
            <div className="col-lg-2">
              <h4 className="mb-4">Help</h4>
              <li className="list-group-item mb-1"><a href="" style={{ opacity: "0.8" }}>Whitepaper</a></li>
              <li className="list-group-item mb-1"><a href="" style={{ opacity: "0.8" }}>FAQ</a></li>
              <li className="list-group-item mb-1"><a href="" style={{ opacity: "0.8" }}>Troubleshooting</a></li>
            </div>
            <div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default App;