let config = {};
let environment = "live";
if (environment === "local") {
  config = {
    BKC_MLMContract: "0x77Be0bddE83A0a6B9e501118A1C918CE2F74D9fd",
    BACKEND_URL: "http://localhost:4000/",
    FRONTEND_URL: "http://localhost:3000/",
    chainId: "0x918",
    rpcUrls: "https://block.valobittestnet.com/",
  
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://testnet.valobittestnet.com",
    VALOBIT_chainId: 2328,
    valobit: "https://block.valobittestnet.com/",
    valobit_explorer_url: "https://testnet.valobittestnet.com",
    claimrewardContract : "0x287e6AeE9A45ecDdd67bd60A33F704ADa3fF03F0"
  };
} else if (environment === "deployment") {
  config = {
    BKC_MLMContract: "0x77Be0bddE83A0a6B9e501118A1C918CE2F74D9fd",
    BACKEND_URL: "https://api.valobitdemo2.com/",
    FRONTEND_URL: "https://valobitdemo2.com/",
    chainId: "0x918",
    rpcUrls:"https://block.valobittestnet.com/",
  
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://testnet.valobittestnet.com",
    VALOBIT_chainId: 2328,
    valobit: "https://block.valobittestnet.com/",
    valobit_explorer_url: "https://testnet.valobittestnet.com",
    claimrewardContract : "0x287e6AeE9A45ecDdd67bd60A33F704ADa3fF03F0"
  };
} else {
  config = {
    BKC_MLMContract: "0x2fa1D0074901ea7db377AF2F4B9376F6305a77af",
    BACKEND_URL: "https://api.vbitcake.io/",
    FRONTEND_URL: "https://vbitcake.io/",
    chainId: "0x9e7",
    rpcUrls:"https://rpc.mnet.vblockchain.io/",
  
    metmaskurl: "https://metamask.io/download/",
    BKC_ContarctLink: "https://valobitscan.io/",
    VALOBIT_chainId: 2535,
    valobit: "https://rpc.mnet.vblockchain.io/",
    valobit_explorer_url: "https://valobitscan.io/",
    claimrewardContract : "0x287e6AeE9A45ecDdd67bd60A33F704ADa3fF03F0"
  };
}

export default config;