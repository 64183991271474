import Logo from "./valobit.png";
import {
  AiOutlineCopy,
  AiOutlineSearch,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { AiOutlineAlignRight } from "react-icons/ai";
import $ from "jquery";
import { useState, useEffect } from "react";
import Web3 from "web3";
import sunflowerABI from "./ABI/vbitmlm.json";
import config from "./config/config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, useWeb3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import VBIT from "./switchnetwork";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Metamask from "./assets/images/Metamask.png";
import Connectwallet from "./assets/images/trustwallet.png";

var jq = $.noConflict();


function Register() {
  const navigate = useNavigate();

  const [isEnabled, setIsEnabled] = useState(true);
  const [userAddress, setUserAddress] = useState("");
  const [message, setMessage] = useState("");
  const [parentAddress, setparentAddress] = useState("0x00");
  const [useridnotexist, setuseridnotexist] = useState("");
  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [parentIdValue, setParentIdValue] = useState("");
  const [Bkcdatatype, setBkcdatatype] = useState([]);
  const [userenterBkcAmount, setuserenterBkcAmount] = useState("");
  const [userbkcpackage, setuserbkcpackage] = useState("");
  const [plantype, setplantype] = useState("");
  const [planamount, setplanamount] = useState("0 VBIT");
  const [userexiststatus, setuserexiststatus] = useState(false);
  const [useridvalue, setuseridvalue] = useState();
  const [show, setShow] = useState(false);
  const { open, close } = useWeb3Modal();

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
  };

  const chains = [VBIT];
  const projectId = "1f0b9efb280db82bff5edc4689ffa76a";

  const trustWalletConnect = async () => {
    const provider = await EthereumProvider.init({
      projectId: "1f0b9efb280db82bff5edc4689ffa76a",
      chains: [1, 56, 2535],
    });
    provider.on("disconnect", (code, reason) => {
      window.location.reload();
      localStorage.clear();
    });
  };

  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  const connectAddress = async () => {
    const userDataString = localStorage.getItem("wagmi.store");
    const userData = JSON.parse(userDataString);
    let address = userData.state.data.account;
    if (address) {
      window.location.reload();
      trustWallet(address);
      setUserAddress(
        address.substring(0, 5) + "..." + address.substring(address.length - 4)
      );
    }
  };

  const trustWallet = async (address) => {
    let web3js = new Web3(config.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      config.BKC_MLMContract
    );
    let userexists = await sunflowerContract.methods
      .isUserExists(address)
      .call();
    if (userexists == true) {
      setuserexiststatus(true);
    } else {
      setuserexiststatus(false);
    }
  };

  const walletopen = async () => {
    let data = await open();
    setInterval(() => {
      if (localStorage.getItem("wagmi.connected")) {
        close();
        window.location.reload(true);
      }
    }, 2000);
  };

  const parentaddresssetval = async (event) => {
    setMessage(event);
    try {
      let web3 = new Web3(config.rpcUrls);
      let sunflowerContract = new web3.eth.Contract(
        sunflowerABI,
        config.BKC_MLMContract
      );
      let findUserID = await sunflowerContract.methods.lastUserId().call();

      let activeUsers = parseInt(findUserID) - 1;

      if (activeUsers >= event) {
        setuseridnotexist("");
        let idBasedAddress = await sunflowerContract.methods
          .idToAddress(event)
          .call();
        setparentAddress(idBasedAddress);
      } else {
        setparentAddress();
        setuseridnotexist("User Id doesn't exist");
      }
    } catch (error) {
      console.log("findParentId error", error);
    }
  };

  const userdashboardshow = async (userMetaAddress) => {
    let web3js = new Web3(config.rpcUrls);
    let sunflowerContract = new web3js.eth.Contract(
      sunflowerABI,
      config.BKC_MLMContract
    );
    let userexists = await sunflowerContract.methods
      .isUserExists(userMetaAddress)
      .call();
    if (userexists == true) {
      navigate('/')
    } else {
      let findUserID = await sunflowerContract.methods.lastUserId().call();
      setuseridvalue(parseInt(findUserID));
      setuserexiststatus(false);
    }
  };

  const handleChange = async (event) => {
    const inputValue = event.target.value;
    if (/^[1-9]\d*$/.test(inputValue)) {
      try {
        let web3 = new Web3(config.rpcUrls);
        let sunflowerContract = new web3.eth.Contract(
          sunflowerABI,
          config.BKC_MLMContract
        );

        let findUserID = await sunflowerContract.methods.lastUserId().call();

        let activeUsers = parseInt(findUserID) - 1;
        const inputValueAsInt = parseInt(inputValue);
        setMessage(inputValueAsInt);
        if (activeUsers >= inputValueAsInt) {
          let idBasedAddress = await sunflowerContract.methods
            .idToAddress(inputValueAsInt)
            .call();
          setparentAddress(idBasedAddress);
          setuseridnotexist(""); // Reset the error message
        } else {
          setparentAddress();
          setuseridnotexist("User Id doesn't exist");
        }
      } catch (error) {
        console.log("findParentId error", error);
      }
    } else {
      setMessage("");
      setparentAddress();
      setuseridnotexist("User Id doesn't exist");
    }
  };

  const findParentId = async (message) => {
    try {
      let web3 = new Web3(config.rpcUrls);
      let sunflowerContract = new web3.eth.Contract(
        sunflowerABI,
        config.BKC_MLMContract
      );
      let findUserID = await sunflowerContract.methods.lastUserId().call();
      let activeUsers = parseInt(findUserID) - 1;
      if (activeUsers >= message) {
        let idBasedAddress = await sunflowerContract.methods
          .idToAddress(message)
          .call();
        setparentAddress(idBasedAddress);
      } else {
        setparentAddress();
        toast.error("User doesn't match..!");
      }
    } catch (error) {
      console.log("findParentId error", error);
    }
  };

  const getallplans = async () => {
    const getallbkc = await axios.get(
      `${config.BACKEND_URL}get-all-plans?network=VBIT`
    );
    setBkcdatatype(getallbkc.data.data);
  };

  useEffect(() => {
    const referralId = window.location.pathname.split("/")[2];
    if(referralId != undefined) {
       setIsEnabled(false);
        getallplans();
        parentaddresssetval(referralId);
        setMessage(referralId);
    }
    let userMetaAddress = localStorage.getItem("address");
    if (userMetaAddress) {
      getallplans();

      if (referralId !== undefined) {
        findParentId(referralId);
        setMessage(referralId);
      }

      if (userMetaAddress != null) {
        userdashboardshow(userMetaAddress);
        setUserAddress(
          userMetaAddress.substring(0, 5) +
            "..." +
            userMetaAddress.substring(userMetaAddress.length - 4)
        );
      }
    } else {
      let userMetaAddress = localStorage.getItem("wagmi.store");
      if (userMetaAddress) {
        let userMeta = JSON.parse(userMetaAddress);
        if (userMeta) {
          let addr = userMeta.state.data.account;
          if (addr) {
            getallplans();
            if (referralId !== undefined) {
              findParentId(referralId);
              setMessage(referralId);
            }
            if(addr != null){
            userdashboardshow(addr);
            setUserAddress(
              addr.substring(0, 5) + "..." + addr.substring(addr.length - 4)
            );
            }
          }
        }
      }
    }
  }, [message]);

  const valobitlogout = async () => {
    localStorage.clear();
    window.location.reload();
  };

  const metamaskconnect = async () => {
    if (window.ethereum) {
      let backchain = config.chainId;
      let metchainid = window.ethereum.chainId;
      if (backchain == metchainid) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        walletswicthethereum();

        let address = localStorage.getItem("address");
        walletdetails();
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        localStorage.setItem("address", accounts[0]);
        let address = localStorage.getItem("address");
        walletdetails();
        walletswicthethereum();
        setUserAddress(
          address.substring(0, 5) +
            "..." +
            address.substring(address.length - 4)
        );
        $("#joinnowbuttonhide").show();
      }
    } else {
      toast.warn("Please install metamask");
      openInNewTab(`https://metamask.io/download/`);
    }
  };

  const walletdetails = async () => {
    const ethereumAddress = wagmiConfig.ethereumAddress;
    let result = localStorage.getItem("address");
    if (result) {
      let address = result;
      let web3js = new Web3(config.rpcUrls);
      let sunflowerContract = new web3js.eth.Contract(
        sunflowerABI,
        config.BKC_MLMContract
      );
      let userexists = await sunflowerContract.methods
        .isUserExists(address)
        .call();
      if (userexists == true) {
        setuserexiststatus(true);
      } else {
        setuserexiststatus(false);
      }
    }
  };

  const walletswicthethereum = async () => {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: config.chainId,
          chainName: "VALOBIT Testnet",
          rpcUrls: [config.rpcUrls],
          blockExplorerUrls: [config.BKC_ContarctLink],
          nativeCurrency: {
            name: "VALOBIT Testnet",
            symbol: "TVBIT",
            decimals: 18,
          },
        },
      ],
    });
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    }
  };


  const register = async () => {
    if (localStorage.getItem("address")) {
      if (message == "" || parentAddress == undefined) {
        toast.error("Parent Id must");
      } else {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        let userAdd = accounts[0];
        let parentIdValue = message;

        if (userenterBkcAmount > 0) {
          try {
            setLoader(true);
            const web3 = new Web3(window.ethereum);

            let amount = await web3.utils.toWei(userenterBkcAmount, "ether");

            const mlmContract = new web3.eth.Contract(
              sunflowerABI,
              config.BKC_MLMContract
            );
            let refAddress;
            if (parentIdValue != "") {
              refAddress = await mlmContract.methods
                .idToAddress(parentIdValue)
                .call();
            } else {
              refAddress = await mlmContract.methods.idToAddress(1).call();
            }
            userdashboardshow(userAdd);
            let transfer = await mlmContract.methods
              .register(refAddress, amount)
              .send({
                from: userAdd,
                value: amount,
              });
            let payload = {
              user_address: userAdd,
              amount: userenterBkcAmount,
              referenceID: `${parentIdValue}`,
              network: "VAL",
              userID: useridvalue,
              package: userbkcpackage,
            };
            const response = await axios.post(
              `${config.BACKEND_URL}add-users`,
              payload
            );
            toast.success("Register successfully");
            setLoader(false);
            navigate("/dashboard");
          } catch (error) {
            setLoader(false);
          }
        } else {
          toast.error("select package");
        }
      }
    } else {
      if (message == "" || parentAddress == undefined) {
        toast.error("Referral Id must");
      } else {
        const userMetaAddress = localStorage.getItem("wagmi.store");
        const userMeta = JSON.parse(userMetaAddress);
        const addr = userMeta.state.data.account;

        const chain = userMeta.state.data.chain.id;
        const address = addr;
        let parentIdValue = message;

        if (userenterBkcAmount > 0) {
          try {
            setLoader(true);
            const provider = await EthereumProvider.init({
              projectId: "1f0b9efb280db82bff5edc4689ffa76a",
              chains: [config.Bsc_chainId],
            });
            const web3 = new Web3(provider);

            let amount = await web3.utils.toWei(userenterBkcAmount, "ether");

            const mlmContract = new web3.eth.Contract(
              sunflowerABI,
              config.BKC_MLMContract
            );
            let refAddress;
            if (parentIdValue != "") {
              refAddress = await mlmContract.methods
                .idToAddress(parentIdValue)
                .call();
            } else {
              refAddress = await mlmContract.methods.idToAddress(1).call();
            }
            userdashboardshow(address);
            let transfer = await mlmContract.methods
              .register(refAddress, amount)
              .send({
                from: address,
                value: amount,
              });
            let addres = address.toLowerCase();
            let payload = {
              user_address: addres,
              amount: userenterBkcAmount,
              referenceID: `${parentIdValue}`,
              network: "VAL",
              userID: useridvalue,
              package: userbkcpackage,
            };
            const response = await axios.post(
              `${config.BACKEND_URL}add-users`,
              payload
            );
            toast.success("Register successfully");
            setLoader(false);
            navigate("/dashboard");
          } catch (error) {
            setLoader(false);
          }
        } else {
          toast.error("Select package");
        }
      }
    }
  };

  const setpackageBkcAmount = async (joinamount, bkcpackage) => {
    $(".dash-new-card").on("click", function () {
      $(".dash-new-card").removeClass("dashboard-active");
      $(this).addClass("dashboard-active");
    });
    setplantype(bkcpackage);
    setplanamount(joinamount + "vbit");
    setuserenterBkcAmount(joinamount);
    setuserbkcpackage(bkcpackage);
  };

  return (
    <div className="App">
      <ToastContainer />

      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid col-lg-11">
          <a class="navbar-brand" href="/">
            <img src={Logo} alt="logo" />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon text-white">
              <AiOutlineAlignRight />
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">

              <li class="nav-item">
                {userAddress ? (
                  <a class="nav-link main-nav-connect-button">{userAddress}</a>
                ) : (
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={handleShow}
                  >
                    Connect Wallet
                  </a>
                )}
              </li>
              <div className="modal-section">
                <WagmiConfig config={wagmiConfig}>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    className="d-flex justify-content-center"
                    centered
                  >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className="pt-1">
                      <div className="d-flex flex-lg-row flex-column gap-lg-1 gap-3">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleClose();
                          metamaskconnect();
                        }}
                        className="connect-metamask-button"
                      >
                        <img src={Metamask} style={{ width: "30px" }} alt="" />
                        Connect Metamask
                      </Button>

                      <Button
                        variant="secondary"
                        onClick={() => {
                          walletopen();
                          handleClose();
                          connectAddress();
                        }}
                        className="connect-metamask-button ms-lg-3"
                      >
                        <img
                          src={Connectwallet}
                          style={{ width: "30px" }}
                          className="me-2"
                          alt=""
                        />
                        Trust Wallet
                      </Button>
                      </div>
                    </Modal.Body>
                  </Modal>
                </WagmiConfig>
                <Web3Modal
                  enableAccountView={true}
                  projectId={projectId}
                  ethereumClient={ethereumClient}
                />
              </div>

              <li class="nav-item">
                {userAddress ? (
                  <a
                    class="nav-link main-nav-connect-button"
                    onClick={valobitlogout}
                  >
                    <AiOutlinePoweroff
                      className="me-2"
                      style={{ fontSize: "18px" }}
                    />
                    Logout
                  </a>
                ) : (
                  <></>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <section class="dashboard-values-design">
        <div class="container-fluid col-lg-11">
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div className="register-card-design">
                <div className="d-flex flex-lg-row gap-3 mb-3 mt-5 justify-content-center">
                  {/* <div>
              <h2 className="fw-bold">Register</h2>
            </div> */}
                  <div className="text-center ref-input">
                    {userexiststatus == true ? (
                      ""
                    ) : (
                      <div className="d-flex flex-lg-row align-items-center mb-3 gap-3 justify-content-center dashboard-search-section">
                        <p className="mb-0">Referral ID</p>
                        <div
                          class="d-flex align-items-center overrall-search-id-section"
                          role="search"
                        >
                          {isEnabled ? (
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={handleChange}
                              // value={message}
                            />
                          ) : (
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={handleChange}
                              value={message}
                              disabled
                            />
                          )}
                        </div>
                      </div>
                    )}
                    <br />
                    <div className="d-flex flex-lg-row align-items-center gap-3 justify-content-center dashboard-search-section">
                      <p className="mb-0">Plan Details</p>
                      <div
                        class="d-flex align-items-center overrall-search-id-section"
                        role="search"
                      >
                        <p className="mb-0">{planamount} </p>
                      </div>
                      {/* <p className="mb-0">Plan Details -</p>
                      <p className="mb-0">{plantype}</p>
                      <p className="mb-0">{planamount} VUSDT</p> */}
                    </div>

                    <p className="container-fluid d-flex col-lg-6 mb-1 mt-3">
                      {useridnotexist}
                    </p>
                  </div>
                </div>

                <div className="row row-cols-1 row-cols-lg-3 mt-2 g-4 mb-5 col-lg-10 mx-auto">
                  <>
                    {Bkcdatatype.map((packageItem, index) => (
                      <div
                        className="col"
                        onClick={() =>
                          setpackageBkcAmount(
                            packageItem.joiningAMT,
                            packageItem.package
                          )
                        }
                      >
                        <div class="card dash-new-card">
                          <div class="card-body py-lg-3 text-center">
                            <div className="">
                              <p className="dashboard-text-8">
                                {packageItem.package}
                              </p>
                              <p className="dashboard-text-9">
                                {packageItem.joiningAMT} VBIT
                              </p>
                              <p>{packageItem.month} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                </div>
                {loader == true ? (
                  <button
                    class="nav-link main-nav-connect-button mx-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                ) : (
                  <button
                    class="nav-link main-nav-connect-button mx-auto"
                    style={{ width: "fit-content" }}
                    onClick={() => register()}
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Register;
