import config from "./config/config"

 const VBIT = {
  id: config.VALOBIT_chainId,
  name: 'VBIT',
  network: 'VBIT',
  nativeCurrency: {
    decimals: 18,
    name: 'VBIT',
    symbol: 'VBIT',
  },
  rpcUrls: {
    public: { http: [config.rpcUrls] },
    default: { http: [config.rpcUrls] },
  },
  blockExplorers: {
    etherscan: { name: 'VBIT', url: config.BKC_ContarctLink },
    default: { name: 'VBIT', url: config.BKC_ContarctLink },
  },
  
}  
export default VBIT