import Error from "./assets/images/404.png"

function App() {

  return (
    <div className="App">
      <div className="container-fluid">
        <div className="row justify-content-center text-center">
          <div className="col">
            <a href="/"><img src={Error} alt="Error" style={{width:"70%"}} /></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
