import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Home from "./Home";
import Dashboard from "./Dashboard";
import ProtectedRoute from "./auth/ProductedRoute";
import Error from "./Error";
import Register from "./Registration";
import $ from "jquery";


function App() {

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/register" element={<ProtectedRoute> {" "} <Register />{" "}</ProtectedRoute>}/>
          <Route path="/register/:network" element={<Register />}/>
          <Route path="*" element={<Error />}/>
          <Route path="/dashboard"
            element={
              <ProtectedRoute>
                {" "}
                <Dashboard />{" "}
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
